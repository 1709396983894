import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      webiny {
        getAuthor(where: {}) {
          data {
            id
            name
            picture
            description
          }
        }
      }
    }
  `)

  const author = data.webiny.getAuthor.data

  return (
    <div className='py-2'>
      <Accordion alloallowMultipleExpanded={false} allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? 
                      <AccordionItemButton>
                        <div className='flex gap-4 items-center justify-left'>
                          <div className=''>
                            <img src={author.picture} title={author.name} alt={author.name} width={50} height={50} className="w-10 rounded-full" />
                          </div>
                          <div className=''>
                            {author?.name && (
                              <div className='!text-xs flex gap-2 items-center justify-center !mb-0 py-3'>
                                By <span className='underline'>{author.name}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </AccordionItemButton>
                    :
                      <AccordionItemButton>
                        <div className='flex gap-4 items-center justify-left'>
                          <div className=''>
                            <img src={author.picture} title={author.name} alt={author.name} width={50} height={50} className="w-10 rounded-full" />
                          </div>
                          <div className=''>
                            {author?.name && (
                              <div className='!text-xs flex gap-2 items-center justify-center !mb-0 py-3'>
                              By <span className='underline'>{author.name}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </AccordionItemButton>
                    )}
                  </AccordionItemState>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className='text-xs bg-grey-200 p-3 border rounded-xl mt-4  shadow-sm shadow-slate-300/25'>{author?.description || null}</div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    </div>
  )
}

export default Bio